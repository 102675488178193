
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    html_: () => import("@/app/modules/html/components/Html.vue"),
    iframe_: () => import("@/app/modules/iframe/components/Iframe.vue"),
    service_: () => import("@/app/modules/service/components/Service.vue"),
    calendar_: () => import("@/app/modules/calendar/components/Calendar.vue"),
    documents_: () => import("@/app/modules/documents/components/Documents.vue"),
    form_: () => import("@/app/modules/form/components/Form.vue"),
    category_: () => import("@/app/modules/category/components/Category.vue"),
    notruf_: () => import("@/app/modules/notruf/components/Notruf.vue"),
    concierge_: () => import("@/app/modules/concierge/components/Concierge.vue"),
    resource_: () => import("@/app/modules/booking/pages/Resource.vue"),
    managementcompany_: () =>
      import("@/app/modules/management-company/components/ManagementCompany.vue"),
    businesscustomer_: () =>
      import("@/app/modules/business-customer/components/BusinessCustomer.vue"),
    mssngrtool_: () => import("@/app/modules/mssngr-tool/components/MSSNGR-tool.vue"),
    documentsforbuilding_: () => import("@/app/modules/documents/pages/CategoryDocuments.vue"),
    documentsforbuildingcomplex_: () =>
      import("@/app/modules/documents/pages/CategoryDocuments.vue")
  }
})
export default class TilePage extends Vue {
  @Prop(Number) readonly tileId!: number;

  get appData() {
    return this.$store.getters["app/data"];
  }

  get tile() {
    let filteredTile;

    const recursiveFilter = (filter, tiles) => {
      return tiles.filter(tile => {
        if (tile.id == this.tileId) filteredTile = tile;
        if (tile.children) recursiveFilter(filter, tile.children);
      });
    };

    recursiveFilter(tile => tile.id == this.tileId, this.appData.tiles);

    return filteredTile;
  }

  get component() {
    return this.tile.type.name.replace(/\s/g, "").toLowerCase() + "_";
  }
}
